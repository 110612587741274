<template>
    <div class="page">
        <div class="title">薪资拆分</div>

        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- picker -->
                拆分月份：<el-date-picker
                    v-model="search.date"
                    type="month"
                    value-format="yyyy-MM"
                ></el-date-picker>

                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">
                    搜索
                </div>
            </div>
            <div class="filter_btns">
                <!-- <div
                    class="custom_button plain no_select"
                    @click="$router.push({ name: 'SalarySplitList' })"
                > -->
                <div
                    class="custom_button plain no_select"
                >
                    拆分条件设置
                </div>
                <div class="custom_button no_select">导出</div>
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            ref="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
                prop="staff.real_name"
                label="姓名"
            ></el-table-column>
            <el-table-column
                prop="staff.labour_type_value"
                label="用工形式"
            ></el-table-column>
            <el-table-column
                prop="company.name"
                label="合同公司"
            ></el-table-column>
            <el-table-column
                prop="staff.department_data.station_name"
                label="岗位"
            ></el-table-column>
            <el-table-column
                prop="final_pay"
                label="应发工资"
            ></el-table-column>
            <el-table-column prop="net_pay" label="拆分状态"></el-table-column>
            <el-table-column
                prop="actual_salary"
                label="企业发放部分"
            ></el-table-column>
            <el-table-column
                prop="pre_salary"
                label="平台发放部分"
            ></el-table-column>
            <el-table-column label="操作" min-width="120" fixed="right">
                <template v-slot:default="scope">
                    <el-dropdown size="mini" split-button type="primary">
                        操作
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-if="scope.row.status == 0"
                                @click.native="
                                    $router.push({
                                        name: 'SalarySplitTable',
                                        params: { id: scope.row.id },
                                    })
                                "
                                >重新拆分</el-dropdown-item
                            >
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <div class="operations">
                        <div
                            v-if="scope.row.status == 0"
                            @click="
                                $router.push({
                                    name: 'SalarySplitTable',
                                    params: { id: scope.row.id },
                                })
                            "
                        >
                            重新拆分
                        </div>
                    </div> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>

        <div class="bottom_betch_operation">
            <el-select class="betch_select" v-model="batch">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <div class="custom_button no_select" @click="toBatchProcessing">
                批量处理
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            search: {
                keyword: "",
                date: this.$options.filters.formatTime(
                    new Date(new Date().setMonth(new Date().getMonth() - 1)),
                    "yyyy-MM"
                ),
            },
            tableData: [],
            page: {
                size: 15,
                page: 1,
                total: 0,
            },
            batch: 1,
            options: [
                {
                    value: 1,
                    label: "打印",
                },
            ],

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        ...mapActions("pay", ["getSalarySplitList", "getToPayment"]),

        loadData(func) {
            // 单例初始化
            if (!this.loadDataObj)
                this.loadDataObj = new LoadDataClass(
                    this.search.date,
                    this.getSalarySplitList
                );

            // 通知，用于修改对象属性
            if (func) func(this.loadDataObj);

            let loading = this.$loading();
            this.loadDataObj
                .load(this.page.page)
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    loading.close();
                })
                .catch((e) => {
                    loading.close();
                    this.$message.error(e);
                });
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            this.loadData((loadDataObj) => {
                loadDataObj.keyword = this.search.keyword;
                loadDataObj.month = this.search.date;
            });
        },

        /**
         * 批量处理
         */
        toBatchProcessing() {
            let selectedItems = this.$refs.table.store.states.selection;
            console.log(selectedItems);
            if (!(selectedItems.length > 0)) {
                this.$message.warning("请至少选择一项进行操作");
                return;
            }
            switch (this.batch) {
                case 1: // 批量打印
                    this.$router.push({
                        name: "SalarySplitPrint",
                        params: {
                            id: selectedItems.map((item) => item.id),
                            date: this.search.date,
                        },
                    });
                    break;

                default:
                    break;
            }
        },
    },
};

class LoadDataClass {
    constructor(month, request, keyword = null) {
        this.month = month;
        this.keyword = keyword;
        this.func = request;
    }

    load(page) {
        let params = {
            month: this.month,
            page,
        };
        if (this.keyword) params.keywords = this.keyword;
        return this.func(params);
    }
}
</script>

<style lang="scss" scoped>
.page {
    position: relative;
    padding-bottom: 50px;
    min-height: calc(100% - 50px);
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}

.bottom_betch_operation {
    position: absolute;
    bottom: 0;
    display: flex;

    .betch_select {
        width: 130px;
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }

        ::v-deep .el-input__icon {
            line-height: 36px;
        }
    }
}
</style>
